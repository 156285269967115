import React, { useState, useEffect } from 'react';
import { Box, Card,  Tab, Tabs, Typography } from '@mui/material';
import Sidebar from '../../../components/Sidebar';
import { styled } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import EditOrder from './EditOrder/Edit';
import View from './CustomerInfo/View';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getOrderDetailAction } from '../../../store/order/action';
import { getExpenseOption } from '../../../store/expenses/action';
import { getTechnicianName } from '../../../store/technician/action';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ExpenseHistory from './ExpenseHistory/ExpenseHistoryTableList';
import { useNavigate } from 'react-router-dom';
import MeasurementTab from './measurement';

const Container = styled(Box)({
  backgroundColor: '#F4F5FA',
  width: '100%',
  height: '100vh',
});


const WrapperItem = styled(Box)({
  padding: '20px 20px 20px 270px',
  backgroundColor: '#F4F5FA',
});

const Orderheading = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const BackButtonWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});




const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};


const OrderDetail = (props: any) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);
  const expenseList = useSelector((state: any) => state?.expenseList);
  const measurementData = useSelector(
    (state: any) => state?.orderDetail.orderDetailData.measurement
  );
  let measureDataShow: any = [];
  {
    measurementData?.map((item: any) => {
      measureDataShow = item;
    });
  }
  const location = useLocation();
  const orderDetaiLoader = useSelector((state: any) => state?.orderDetail.loading);
  let navigate = useNavigate();
  useEffect(() => {
    dispatch(
      getOrderDetailAction({
        _id: location?.state,
      })
    );
    dispatch(getTechnicianName());
    dispatch(getExpenseOption());
  }, []);
 
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
 

  return (
    <Container>
      <Sidebar />
      <WrapperItem>
        <Orderheading>
          <BackButtonWrapper>
            <ArrowBackRoundedIcon
              onClick={() => navigate(-1)}
              sx={{ fontSize: 30, marginRight: '20px', color: 'black' }}
            />
          </BackButtonWrapper>
          <Typography
            variant="h5"
            sx={{
              color: '#9155FD',
              fontWeight: 600,
            }}
          >
            Order Details
          </Typography>
        </Orderheading>
        {!orderDetaiLoader && <View />}
        {!orderDetaiLoader && (
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            sx={{ backgroundColor: '#F4F5FA' }}
          >
            <Tab label="Edit Order" sx={{ fontSize: '16px' }} />
            <Tab label="Expense History" sx={{ fontSize: '16px' }} />
            <Tab label="Measurements" sx={{ fontSize: '16px' }} />
          </Tabs>
        )}
        <TabPanel value={value} index={0}>
          <EditOrder />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ExpenseHistory />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MeasurementTab/>
        </TabPanel>
      </WrapperItem>
    </Container>
  );
};

export default OrderDetail;
