import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Sidebar from '../../components/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Pagination } from '@mui/material';
import SearchBar from '../../components/SearchBar';
import AddEditMeasurement from './AddEditMeasurement';
import MeasurementListTable from './TableItem';
import { getMesurementList } from '../../store/mesurement/action';

const Container = styled(Box)({
  backgroundColor: '#F4F5FA',
  width: '100%',
  height: '100vh',
});

const WrapperItem = styled(Box)({
  padding: '20px 20px 20px 270px',
  backgroundColor: '#F4F5FA',
});

const SearchBarWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '10px',
  '@media (max-width:1024px)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: '10px',
    justifyContent: 'space-between',
  },
});

const PaginationWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'right',
  marginTop: '12px',
  fontWeight: 'bold',
});

const measurementsHeader = [
  'Customer Name',
  'Customer Number',
  'Created Date',
  'Action',
];

const MeasurementList = () => {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isAddModal, setIsModal] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const mesurementListDataPagination = useSelector(
    (state: any) => state?.mesurementList?.measurementData
  );
  const auth = useSelector((state: any) => state.auth);
  const measurementData = useSelector((state: any) => state?.mesurementList);
  const count = Math.ceil(mesurementListDataPagination?.total / 10);
  const measurementLoading = measurementData?.loading; 
  const isLoader = useSelector(
    (state: any) => state?.mesurementList.isLoading
  );

  const handleChange = (event: any, value: any) => {
    dispatch(getMesurementList({  id: auth.data._id, page:value, searchTerm, rowsPerPage: 10 }));
    setPage(value);
  };
  const getMeasurement = (searchTerm: string): any => {
     setPage(1);
    dispatch(getMesurementList({  id: auth.data._id, page: 1, searchTerm, rowsPerPage: 10 }));
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
      dispatch(getMesurementList({  id: auth.data._id, page, searchTerm, rowsPerPage: 10 }));
  }, [page]);

  useEffect(() => {
    if (!measurementLoading) {
      setShowDeleteModal(false);
    }
  }, [!measurementLoading]);
  useEffect(() => {
    if (!isLoader) {
      setIsModal(false);
    }
  }, [!isLoader]);

  return (
    <Container>
      <Sidebar />
      <WrapperItem>
        <Typography variant='h5' sx={{ color: '#9155FD', fontWeight: 'bold' }}>
          Measurement List
        </Typography>
        <SearchBarWrapper>
          <SearchBar onSearch={getMeasurement} />

          <Button
            onClick={() => setIsModal(true)}
            size='medium'
            variant='contained'
            sx={{
              fontSize: '16px',
              marginLeft: '10px',
              backgroundColor: '#9155FD !important',
              '@media (max-width: 1076px)': { marginTop: '10px' },
            }}
          >
            Create
          </Button>
        </SearchBarWrapper>
        <MeasurementListTable
          measurementList={measurementData?.measurementData?.data}
          isAddModal={isAddModal}
          editedData={editedData}
          setIsModal={(value: any) => {
            setIsModal(value);
            if (!value) setEditedData(null);
          }}
          setEditedData={setEditedData}
          page={page}
          setPage={setPage}
          measurementListHeader={measurementsHeader}
          measurementDataLoader={measurementLoading}
        />
        {isAddModal && (
          <AddEditMeasurement
            open={isAddModal}
            editedData={editedData}
            onClose={() => {
              setIsModal(false);
              setEditedData(null);
            }}
          />
        )}
        {measurementData?.measurementData?.data?.length > 0 &&
          !measurementLoading && (
            <PaginationWrapper>
              <Pagination
                sx={{
                  '& .Mui-selected': {
                    backgroundColor: '#9155FD !important',
                    color: 'white !important',
                  },
                  '& .Mui-selected:hover': {
                    backgroundColor: '#9155FD !important',
                    color: 'white',
                  },
                }}
                count={count}
                page={page}
                onChange={handleChange}
              />
            </PaginationWrapper>
          )}
      </WrapperItem>
    </Container>
  );
};

export default MeasurementList;
