import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Button,
  Box,
  Grid,
  Card,
  Typography,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import Sidebar from '../../../components/Sidebar';
import { AddOrderValidationSchema } from '../../../utils/validations';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { addOrder} from '../../../store/order/action';
import { useEffect, useState } from 'react';
import CustomSelect from '../../../components/CustomSelect';
import DatePickers from '../../../components/DatePickers';
import { CircularProgress } from '@mui/material';
import OrderItems from './OrderItems';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import MeasurementConfirmationModal from '../../../components/MeasurementConfirmationModal';

const Container = styled(Box)({
  backgroundColor: '#F4F5FA',
  width: '100%',
  height: '100vh',
});

const WrapperItem = styled(Box)({
  padding: '20px 20px 20px 270px',
  backgroundColor: '#F4F5FA',
});

const StyledTableContainer = styled(Card)(({ theme }) => ({
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  borderRadius: theme.spacing(1),
  marginTop: '16px',
  height: '80vh',
  overflowY: 'auto !important' as 'auto',
  padding: theme.spacing(3),
}));

const CardWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '80vh',
  '@media (max-width: 1024px)': {
    overflowX: 'scroll',
  },
});

const ButtonWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '10px',
  marginTop: 30,
  paddingBottom: 20,
});
const BackButtonWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
const Image = styled('img')(({ theme }) => ({
  height: 100,
  width: 100,
  borderRadius: theme.spacing(1),
  border: 'none',
  objectFit: 'contain',
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  width: '200px',
  marginBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  position: 'relative',
}));

const Orderheading = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const TextareaElement = styled(TextareaAutosize, {
  shouldForwardProp: (prop) =>
    !['ownerState', 'minRows={3}', 'maxRows'].includes(prop.toString()),
})(
  ({ theme }) => `
  width: 90%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 8px 12px;
  border-radius: 8px 8px 0 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === 'dark' ? blue[700] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};
const Paper = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px !important',
  marginTop: '10px',
};

const AddOrder = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.auth);
  const isLoader = useSelector((state: any) => state?.order.isLoading);
  const [open, setOpen] = React.useState(false);
  const [newItemError, setNewItemError] = useState('');
  const [image, setImage] = useState<{ file: any; preview: string }>({
    file: null,
    preview: '',
  });
  const [measurementExistId, setMeasurementExistId] = useState<any>()
  const orderDetail = useSelector((state: any) => state?.orderDetail);
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const preview = URL.createObjectURL(file!);
      setImage({ file, preview });
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      customerName: '',
      customerNumber: '',
      customerEmail: '',
      customerAddress: '',
      deliveryDate: '',
      orderDate: '',
      orderDetails: [
        {
          itemName: '',
          quantity: '',
          originalPrice: '',
          discount: '',
          cgst: '',
          sgst: '',
          displayPrice: '',
        },
      ],
      grandTotal: '',
      totalDiscount: '',
      finalAmount: '',
      advancePayment: '',
      remainingPayment: '',
      paymentMode: 'cash',
      notes: '',
      images: '',
    },
    validationSchema: AddOrderValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append('customerName', values.customerName);
      formData.append('customerNumber', values.customerNumber);
      formData.append('customerEmail', values.customerEmail);
      formData.append('customerAddress', values.customerAddress);
      formData.append('deliveryDate', values.deliveryDate);
      formData.append('orderDate', values.orderDate);
      if(measurementExistId){
        formData.append('measurementId', measurementExistId);
      }
      const orderDetailsArray = values.orderDetails.map((item) => ({
        itemName: item.itemName,
        quantity: item.quantity,
        originalPrice: item.originalPrice,
        discount: item.discount,
        cgst: item.cgst,
        sgst: item.sgst,
        displayPrice: item.displayPrice,
      }));

      formData.append('orderDetails', JSON.stringify(orderDetailsArray));
      formData.append('grandTotal', values.grandTotal);
      formData.append('totalDiscount', values.totalDiscount);
      formData.append('finalAmount', values.finalAmount);
      formData.append('advancePayment', values.advancePayment);
      formData.append('remainingPayment', values.remainingPayment);
      formData.append('paymentMode', values.paymentMode);
      formData.append('notes', values.notes);
      formData.append('images', image.file);
      dispatch(addOrder({ payload: formData, id: auth.data._id }));
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = formik;
  useEffect((index?: any) => {
    if (location.state) {
      const { state } = location;
      setImage({ file: null, preview: state.profilePic });
      setFieldValue('customerName', state.customerName);
      setFieldValue('customerNumber', state.customerNumber);
      setFieldValue('customerEmail', state.customerEmail);
      setFieldValue('customerAddress', state.customerAddress);
      setFieldValue('deliveryDate', state.deliveryDate);
      setFieldValue('orderDate', state.orderDate);
      setFieldValue(`orderDetails`, [...state?.orderDetails]);
      setFieldValue('grandTotal', state.grandTotal);
      setFieldValue('totalDiscount', state.totalDiscount);
      setFieldValue('finalAmount', state.finalAmount);
      setFieldValue('advancePayment', state.advancePayment);
      setFieldValue('remainingPayment', state.remainingPayment);
      setFieldValue('paymentMode', state.paymentMode);
      setFieldValue('notes', state.notes);
      setFieldValue('images', image.preview);
    }
  }, []);
  
  const handleSubmitCallback = (id: any) => {
    setMeasurementExistId(id)
    formik.handleSubmit();
  }

  const handleChangeInput = (event: any) => {
    setFieldValue(`notes`, event.target.value);
  };

  const handleAddItems = () => {
    if (isNewAddAllowed) {
      setFieldValue('orderDetails', [
        ...values.orderDetails,
        {
          itemName: '',
          quantity: '',
          originalPrice: '',
          discount: '',
          cgst: '',
          sgst: '',
          displayPrice: '',
        },
      ]);
    } else {
      setNewItemError('Please fill the description');
    }
  };

  const options = [
    { value: 'cash', label: 'cash' },
    { value: 'online', label: 'online' },
  ];

  const handleChangeDiscountedTotal = (event: any) => {
    setFieldValue(`totalDiscount`, Number(event.target.value));
    const grandTotal = Number(values.grandTotal);
    const totalDiscount = Number(event.target.value);
    const finalAmount = grandTotal - (grandTotal * totalDiscount) / 100;
    const remainingPayment = finalAmount - Number(values.advancePayment);
    setFieldValue(`finalAmount`, finalAmount);
    setFieldValue(`grandTotal`, finalAmount);
    setFieldValue(`remainingPayment`, remainingPayment);
  };

  const handleRemPayment = (event: any) => {
    setFieldValue(`advancePayment`, Number(event.target.value));
  };

  let isNewAddAllowed = true;

  for (let i = 0; i < values.orderDetails.length; i++) {
    let data = values.orderDetails[i];
    let t = Object.values(data).some((val) => val);
    if (!t) {
      isNewAddAllowed = false;
    }
  }

  useEffect(() => {
    setNewItemError('');
  }, [isNewAddAllowed]);

  return (
    <Container>
      <Sidebar />
      <WrapperItem>
        <Orderheading>
          <BackButtonWrapper>
            <Link to='/orders'>
              <ArrowBackRoundedIcon
                sx={{ fontSize: 30, marginRight: '20px', color: 'black' }}
              />
            </Link>
          </BackButtonWrapper>
          <Typography variant='h5' sx={{ color: '#9155FD', fontWeight: 600 }}>
            {location.state ? 'Edit Order' : 'Create Order'}
          </Typography>
        </Orderheading>
        <StyledTableContainer>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <CardWrapper>
                <Box sx={{ marginBottom: 4 }}>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ sm: 2, md: 7 }}
                  >
                    <Grid item xs={12} md={4} container sx={Paper}>
                      <Grid item xs={12}>
                        <TextField
                          placeholder='Customer Name'
                          variant='outlined'
                          fullWidth
                          size='small'
                          {...getFieldProps('customerName')}
                          error={Boolean(
                            touched.customerName && errors.customerName
                          )}
                          helperText={
                            touched.customerName && errors.customerName
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} container sx={Paper}>
                      <Grid item xs={12}>
                        <TextField
                          placeholder='Customer Phone'
                          variant='outlined'
                          fullWidth
                          size='small'
                          type='number'
                          {...getFieldProps('customerNumber')}
                          error={Boolean(
                            touched.customerNumber && errors.customerNumber
                          )}
                          helperText={
                            touched.customerNumber && errors.customerNumber
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} container sx={Paper}>
                      <Grid item xs={12}>
                        <TextField
                          placeholder='Customer Email'
                          variant='outlined'
                          fullWidth
                          size='small'
                          {...getFieldProps('customerEmail')}
                          helperText={
                            touched.customerEmail && errors.customerEmail
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} container sx={Paper}>
                      <Grid item xs={12}>
                        <TextField
                          placeholder='Customer Address'
                          variant='outlined'
                          fullWidth
                          size='small'
                          {...getFieldProps('customerAddress')}
                          error={Boolean(
                            touched.customerAddress && errors.customerAddress
                          )}
                          helperText={
                            touched.customerAddress && errors.customerAddress
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} container sx={Paper}>
                      <Grid item xs={12}>
                        <DatePickers
                          placeholder={'Order Date'}
                          value={formik.values.orderDate}
                          onChange={(value: any) => {
                            formik.setFieldValue('orderDate', value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} container sx={Paper}>
                      <Grid item xs={12}>
                        <DatePickers
                          placeholder={'Delivery Date'}
                          value={formik.values.deliveryDate}
                          onChange={(value: any) => {
                            formik.setFieldValue('deliveryDate', value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <OrderItems
                    items={values.orderDetails}
                    getFieldProps={getFieldProps}
                  />
                </Box>
                <Box>
                  <div style={{ marginBottom: 10, color: 'red' }}>
                    {newItemError}
                  </div>
                  <Button
                    variant='contained'
                    sx={{
                      backgroundColor: '#9155FD !important',
                      marginBottom: 5,
                    }}
                    onClick={handleAddItems}
                  >
                    Add Item
                  </Button>
                  <Box
                    sx={{
                      display: 'flex',
                      mb: 1,
                      mt: 2,
                      '@media (max-width: 899px)': {
                        display: 'flex',
                        flexDirection: 'column',
                      },
                      '@media only screen and (min-width: 900px) and (max-width: 1200px) ':
                        {
                          display: 'flex',

                          flexDirection: 'column',
                        },
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={4}
                      container
                      sx={Paper}
                      style={{ marginRight: '20px' }}
                    >
                      <Grid item xs={6}>
                        <Typography>Grand Total :</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          placeholder='0'
                          variant='outlined'
                          fullWidth
                          size='small'
                          type='text'
                          value={
                            values.grandTotal && values.grandTotal !== '0'
                              ? values.grandTotal
                              : ''
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={4}
                      container
                      sx={Paper}
                      style={{ marginRight: '20px' }}
                    >
                      <Grid item xs={6}>
                        <Typography>Discount On Total(%) :</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          placeholder='0'
                          variant='outlined'
                          fullWidth
                          size='small'
                          type='number'
                          {...getFieldProps('totalDiscount')}
                          error={Boolean(
                            touched.totalDiscount && errors.totalDiscount
                          )}
                          helperText={
                            touched.totalDiscount && errors.totalDiscount
                          }
                          // onChange={(e) => handleChangeDiscountedTotal(e)}
                          onBlur={(e) => handleChangeDiscountedTotal(e)}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} container sx={Paper}>
                      <Grid item xs={6}>
                        <Typography>Final Amount:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          placeholder='0'
                          variant='outlined'
                          fullWidth
                          size='small'
                          type='text'
                          value={
                            values.finalAmount && values.finalAmount !== '0'
                              ? values.finalAmount
                              : ''
                          }
                          error={Boolean(
                            touched.finalAmount && errors.finalAmount
                          )}
                          helperText={touched.finalAmount && errors.finalAmount}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      mb: 1,
                      '@media (max-width: 899px)': {
                        display: 'flex',
                        flexDirection: 'column',
                      },
                      '@media only screen and (min-width: 900px) and (max-width: 1200px) ':
                        {
                          flexDirection: 'column',
                        },
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={4}
                      container
                      sx={Paper}
                      style={{ marginRight: '25px' }}
                    >
                      <Grid item xs={6}>
                        <Typography>Advance Payment :</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          placeholder='0'
                          variant='outlined'
                          fullWidth
                          size='small'
                          type='number'
                          {...getFieldProps('advancePayment')}
                          error={Boolean(
                            touched.advancePayment && errors.advancePayment
                          )}
                          helperText={
                            touched.advancePayment && errors.advancePayment
                          }
                          onBlur={(e) => handleRemPayment(e)}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={4}
                      container
                      sx={Paper}
                      style={{ marginRight: '20px' }}
                    >
                      <Grid item xs={6}>
                        <Typography>Remaining Payment:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          placeholder='0'
                          variant='outlined'
                          fullWidth
                          size='small'
                          type='text'
                          {...getFieldProps('remainingPayment')}
                          error={Boolean(
                            touched.remainingPayment && errors.remainingPayment
                          )}
                          helperText={
                            touched.remainingPayment && errors.remainingPayment
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} lg={4} container sx={Paper}>
                      <Grid item xs={6}>
                        <Typography>Payment Mode :</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <CustomSelect
                          options={options}
                          value={formik.values.paymentMode}
                          onChange={(value: any) =>
                            formik.setFieldValue('paymentMode', value.value)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      mb: 1,
                      width: '100%',
                      '@media (max-width: 899px)': {
                        display: 'flex',
                        flexDirection: 'column',
                      },
                      '@media only screen and (min-width: 900px) and (max-width: 1200px) ':
                        {
                          flexDirection: 'column',
                        },
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={6}
                      container
                      alignItems={'center'}
                    >
                      <Grid item xs={6} lg={3} alignItems={'center'}>
                        <Typography>Notes :</Typography>
                      </Grid>
                      <Grid item xs={6} lg={7} alignItems={'center'}>
                        <TextareaElement
                          aria-label='Demo input'
                          minRows={3}
                          required={false}
                          placeholder='Notes…'
                          {...getFieldProps('notes')}
                          onChange={(e) => handleChangeInput(e)}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={6}
                      container
                      alignItems={'center'}
                    >
                      <Grid item xs={6} lg={4} alignItems={'center'}>
                        <Button
                          variant='contained'
                          sx={{
                            backgroundColor: '#9155FD !important',
                            height: '35px',
                          }}
                          component='label'
                        >
                          Upload
                          <input
                            hidden
                            accept='image/*'
                            type='file'
                            onChange={handleImageUpload}
                          />
                        </Button>
                      </Grid>
                      <Grid item xs={6} lg={5} alignItems={'center'}>
                        <ImageContainer>
                          {image.preview && (
                            <CancelIcon
                              onClick={() =>
                                setImage({ file: null, preview: '' })
                              }
                              style={{
                                position: 'absolute',
                                zIndex: 1,
                                right: '-15px',
                                marginTop: '10px',
                                color: '#7149C6',
                                cursor: 'pointer',
                              }}
                            />
                          )}
                          {image.preview && (
                            <Image
                              src={image.preview && image.preview}
                              style={{
                                marginLeft: '20px',
                                marginTop: '15px',
                                width: '150px',
                                height: '100px',
                              }}
                            />
                          )}
                        </ImageContainer>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <ButtonWrapper>
                  <Button
                    size='medium'
                    sx={{
                      placeSelf: 'center',
                      backgroundColor: `${formik.isValid ? '#9155FD' : '#CCCCCC'} !important`,
                      marginRight: '8px',
                      width: '80px',
                    }}
                    variant='contained'
                    disabled={!formik.isValid}
                    onClick={handleClickOpen}
                  >
                    {isLoader ? (
                      <CircularProgress
                        color='inherit'
                        style={{
                          color: '#FFFFFF',
                          height: '25px',
                          width: '25px',
                        }}
                      />
                    ) : location.state ? (
                      'Update'
                    ) : (
                      'Save'
                    )}
                  </Button>
                  <Link to='/orders'>
                    <Button variant='outlined' sx={{ placeSelf: 'center' }}>
                      Cancel
                    </Button>
                  </Link>
                </ButtonWrapper>
              </CardWrapper>
            </Form>
          </FormikProvider>
        </StyledTableContainer>
      </WrapperItem>
      <MeasurementConfirmationModal
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        handleSubmit= {handleSubmit}
        handleSubmitCallback={handleSubmitCallback}
        open={open}
      />
    </Container>
  );
};

export default AddOrder;
