import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Card,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  IconButton,
  Menu,
  FormControl,
  Select,
  SelectChangeEvent,
  styled,
  MenuItem,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { AddEditExpenseValidationSchema } from '../../../../utils/validations';
import {
  addExpenses,
  deleteOrderExpense,
  expenseStatusChange,
} from '../../../../store/expenses/action';
import {
  addTechnicianPayment,
  deleteTechnicianPayment,
  editTechnicianPayment,
} from '../../../../store/technician/action';
import AddExpensesTable from './AddEdit';
import EditButton from '../../../../components/EditButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import DeleteButton from '../../../../components/DeleteButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TechnicianPaymentModal from './TechnicianPayment/AddPaymentModal';
import DropdownModal from '../../../../components/ConfirmationModal';
import EditTechnicianPaymentModal from './TechnicianPayment/EditPaymentModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { formatIndianCurrency } from '../../../../utils/validations/Helpler';
const ExpenseListHeader = [
  '',
  'Technician Name',
  'Description',
  'Amount (₹)',
  'Advance Payment (₹)',
  'Remaining Payment (₹)',
  'Delivery Date',
  'Status',
  'Action',
];

const HsitoryTableHeader = ['Date', 'Amount', 'Remaining Amount', 'Action'];

const CardContainer = styled(Card)({
  boxShadow: '0px 2px 10px 0px rgb(58 53 65 / 10%) !important',
  borderRadius: '6px !important',
  height: '65vh !important',
  padding: '20px',
  overflowY: 'auto !important' as 'auto',
});

const CollapseContainer = styled(Collapse)({
  margin: '0px',
  backgroundColor: 'rgb(249, 250, 252)',
});

const TableHeadName = styled(TableHead)({
  position: 'sticky',
  top: 0,
  alignItems: 'center',
  backgroundColor: 'rgb(249, 250, 252)',
  zIndex: 999,
});

const TableHeader = styled(TableCell)({
  color: 'rgba(58, 53, 65, 0.87) !important',
  fontWeight: '600 !important' as any,
});


const TableBox = styled(Box)({
  paddingTop: '5px',
  paddingBottom: '5px',
  paddingLeft: '0',
  width: '60%',
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'left',
  textAlign: 'left',
  margin: '10px 50px 20px 70px ',
});



const TableDesign = styled(Table)({
  border: '2px solid black',
  borderColor: 'black',
  borderRadius: '5px',
  alignItems: 'left',
  textAlign: 'left',
  overflow: 'hidden',
});

const StyledRow = styled(TableRow)({
  border: '2px ',
  textColor: 'black',
  alignItems: 'left !importatant',
  textAlign: 'left',
});

const CustomText = styled(Typography)({
  marginTop: '20px !important',
  marginLeft: '110px !important',
  width: '30%',
  margin: 'auto',
  fontSize: '1rem !important',
  fontWeight: 'bold !important' as any,
});

const NoDatTextSize = styled('div')({
  fontSize: '30px',
});

const ExpenseHistory = () => {
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [expenseHistory, setExpenseHistory] = useState<any>([]);
  const [editData, setEditData] = useState<any>([]);
  const [arrowOpen, setArrowOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openTechnicianPaymentModal, setTechnicianPaymentModal] = useState(false);
  const [technicianPaymentData, setTechnicianPaymentData] = useState<any>({});
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteItemId, setDeleteItemId] = useState<string>('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [editTechnicianPaymentData, setEditTechnicianPaymentData] = useState({});
  const [expensePaymentHistory, setExpensePaymentHistory] = useState([])
  const [showExpenseDeleteModal, setShowExpenseDeleteModal] = useState(false);
  const [expenseDeleteItemId, setExpenseDeleteItemId] = useState<string>('');
  const [openRows, setOpenRows] = useState<{ [key: number]: boolean }>({});
  const [dropdownValues, setDropdownValues] = useState<any>({});
  const [dropdownModalValues, setDropdownModalValues] = useState<any>({
    e: '',
    item: '',
  });
  const [dropdownConfirmationItemId, setDropdownConfirmationItemId] =
    useState('');
  const [dropdownConfirmationValue, setDropdownConfirmationValue] =
    useState('');
  const [showDropdownConfirmation, setShowDropdownConfirmation] =
    useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const open = Boolean(anchorEl);

  const isSuccess = useSelector((state: any) => state?.addTechnicianPayment?.isSuccess);
  const isEditSuccess = useSelector((state: any) => state?.editTechnicianPayment?.isSuccess);
  const expenseDataLoader = useSelector((state: any) => state?.expense?.loading);
  const orderDetail = useSelector((state: any) => state?.orderDetail);
  const isAddExpense = useSelector((state: any) => state?.addExpense?.isSuccess);
  const isExpenseLoading = useSelector((state: any) => state?.addExpense?.loading);
  const orderId = orderDetail?.orderDetailData._id;

  const options = [
    { value: 'placed', label: 'Placed' },
    { value: 'inProgress', label: 'In Progress' },
    { value: 'delayed', label: 'Delayed' },
    { value: 'completed', label: 'Completed' },
    { value: 'cancelled', label: 'Cancelled' },
  ];

  const formik = useFormik({
    initialValues: {
      expenseDetail: [
        {
          technicianName: '',
          technicianId: '',
          description: '',
          amount: '',
          deliveryDate: '',
          advancePayment: '',
          remainingPayment: '',
          searchTechnician: '',
          searchExpense: '',
        },
      ],
    },
    validationSchema: AddEditExpenseValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const numbericField = ['advancePayment', 'amount'];
      let expenses = values.expenseDetail.map((el) =>
        Object.entries(el).reduce((prev, curr: [string, string]) => {
          if (numbericField.includes(curr[0])) {
            prev[curr[0]] = Number(curr[1]);
          } else {
            prev[curr[0]] = curr[1];
          }
          return prev;
        }, {} as Record<string, number | string>)
      );

      const expenseEdit = expenses.map((item: any, index: any) => ({
        ...item,
        _id: editData._id,
      }));
      dispatch(
        addExpenses({
          payload: {
            orderId,
            action: isEdit ? 'edit' : 'add',
            expenses: isEdit ? JSON.stringify(expenseEdit) : JSON.stringify(expenses),
            _id: location.state,
          },
        })
      );
    },
  });

  const { values, setFieldValue } = formik;

  const isNewAddAllowed = useMemo(
    () =>
      values.expenseDetail.every((el) => Object.values(el).every((el) => el)),
    [values]
  );

  useEffect(() => {
    if (isAddExpense) {
      setIsEdit(false);
      setIsAdd(false);
    }
  }, [isAddExpense]);

  useEffect(() => {
    if (isSuccess) {
      handleCloseModel();
      setTechnicianPaymentModal(false);
    }
  }, [isSuccess]);

  const handleDropdownConfirmationAction = (e: any, item: any) => {
    setDropdownModalValues({ e, item });
    setShowDropdownConfirmation(true);
  };

  useEffect(() => {
    if (isEditSuccess) {
      handleCloseModel();
      setShowEditModal(false);
    }
  }, [isEditSuccess]);

  const handleAddItems = () => {
    setFieldValue('expenseDetail', [
      ...values.expenseDetail,
      {
        technicianId: '',
        technicianName: '',
        description: '',
        amount: '',
        deliveryDate: '',
        remainingPayment: '',
        searchTechnician: '',
        searchExpense: '',
      },
    ]);
  };

  const auth = useSelector((state: any) => state.auth);

  const deleteTechnicianPaymentLoader = useSelector(
    (state: any) => state.deleteTechnicianPayment.loading
  );


  useEffect(() => {
    if (!deleteTechnicianPaymentLoader) {
      setShowDeleteModal(false);
    }
  }, [!deleteTechnicianPaymentLoader]);


  useEffect(() => {
    if (orderDetail?.orderDetailData) {
      setExpenseHistory(orderDetail?.orderDetailData?.expenseHistory);
    }

  }, [orderDetail?.orderDetailData]);

  const handleDeleteModalYes = () => {
    dispatch(deleteTechnicianPayment({ payload: { id: deleteItemId, _id: location.state } }));
  };

  const handleCloseDropdownConfirmationModal = () => {
    setDropdownConfirmationItemId('');
    setDropdownConfirmationValue('');
    setShowDropdownConfirmation(false);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setDeleteItemId('');
  };

  const handleDeleteExpenseModalClose = () => {
    setShowExpenseDeleteModal(false);
    setExpenseDeleteItemId('');
  };

  const deleteExpensePaymentLoader = useSelector(
    (state: any) => state.deleteOrderExpense.loading
  );
  const editExpenseStatusLoader = useSelector(
    (state: any) => state.editExpenseStatus.loading
  );

  useEffect(() => {
    if (!editExpenseStatusLoader) {
      setShowDropdownConfirmation(false);
    }
  }, [!editExpenseStatusLoader]);



  useEffect(() => {
    if (!deleteExpensePaymentLoader) {
      setShowExpenseDeleteModal(false);
    }
  }, [!deleteExpensePaymentLoader]);

  const handleClickYes = (event: SelectChangeEvent, item: any) => {
    setShowDropdownConfirmation(true);
    let data = {
      expenseId: orderDetail?.orderDetailData?.expenseId,
      technicianId: item.technicianId,
      status: event.target.value,
      description: item.description
    }
    dispatch(expenseStatusChange(data));
    const updatedValues = { ...dropdownValues, [item._id]: event.target.value };
    setDropdownValues(updatedValues);
  };


  const handleDeleteExpenseModalYes = () => {
    dispatch(deleteOrderExpense({ payload: { id: expenseDeleteItemId, _id: location.state } }));
  };

  const navToAddExpense = () => {
    setEditData([]);
    setIsAdd(true);
    setIsEdit(false);
  };



  const navToEditTechnicianPayment = (data: any, index: number) => {
    setExpensePaymentHistory(data)
    var arrayItemByIndex = data[index]
    setEditTechnicianPaymentData(arrayItemByIndex);
    setShowEditModal(true);
  };

  const handleRemoveTechnicianPayment = (data: any) => {
    setDeleteItemId(data);
    setShowDeleteModal(true);
  };

  const handleRemoveExpense = (data: any) => {
    setShowExpenseDeleteModal(true);
    setExpenseDeleteItemId(data);
  };

  const navToEditExpense = (data: any) => {
    setEditData(data);
    setIsEdit(true);
    setIsAdd(false);
  };

  const handleCancelData = () => {
    setEditData([]);
    setIsEdit(false);
    setIsAdd(false);
  };

  const handleCloseModel = () => {
    setAnchorEl(null);
  };



  const handleViewModel = (e: any, item: any) => {
    setTechnicianPaymentModal(true);
    setTechnicianPaymentData(item);
    setAnchorEl(e.currentTarget);
    setArrowOpen(!arrowOpen);
  };

  const closeTechnicianPaymentModal = () => {
    setTechnicianPaymentModal(false);
  };

  const closeEditTechnicianPaymentModal = () => {
    setShowEditModal(false);
  };

  const saveData = (saveData: any) => {
    dispatch(
      addTechnicianPayment({ payload: saveData, _id: location.state, id: auth.data._id })
    );
  };


  const updateData = (updateData: any, index: number) => {
    dispatch(
      editTechnicianPayment({ payload: updateData, _id: location.state })
    );
    setArrowOpen(!arrowOpen);
  };

  const openAccordian = (expenseHistoryData: any, index: number) => {
    setOpenRows((prevOpenRows) => {
      const updatedRows = { ...prevOpenRows };
      updatedRows[index] = !updatedRows[index];
      return updatedRows;
    });
  };


  return (
    <CardContainer>
      {isAdd || isEdit ? (
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <AddExpensesTable
              items={values.expenseDetail}
              handleChange={formik.handleChange}
              handleAddItems={handleAddItems}
              formik={formik}
              isNewAddAllowed={isNewAddAllowed}
              editedData={editData}
              isEdit={isEdit}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 20,
              }}
            >
              {!expenseDataLoader && (
                <div>
                  <Button
                    disabled={values.expenseDetail[0].amount ? false : true}
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: values.expenseDetail[0].amount ? '#9155FD !important' : '#9155FD',
                      marginTop: 2,
                      marginRight: '8px',
                    }}
                  >
                    {isExpenseLoading ? (
                      <CircularProgress
                        color="inherit"
                        style={{
                          color: '#FFFFFF',
                          height: '25px',
                          width: '25px',
                        }}
                      />
                    ) : (
                      'Save'
                    )}
                  </Button>
                  <Button
                    onClick={handleCancelData}
                    variant="outlined"
                    sx={{ placeSelf: 'center', marginTop: 2 }}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          </form>
        </FormikProvider>
      ) : (
        <Box>
          <Box
            sx={{
              display: 'flex',
              marginBottom: '10px',
              justifyContent: 'right',
            }}
          >
            <Button
              size="medium"
              sx={{ backgroundColor: '#9155FD !important' }}
              variant="contained"
              onClick={() => navToAddExpense()}
            >
              Add Expense
            </Button>
          </Box>
        </Box>
      )}
      <TableContainer>
        <Table>
          <TableHeadName>
            <TableRow>
              {ExpenseListHeader?.map((item: string, index: number) => (
                <TableHeader

                  key={index}
                  align="center"
                  sx={{ fontSize: '16px', fontWeight: 'bold', whiteSpace: 'nowrap' }}
                >
                  {item}
                </TableHeader>
              ))}
            </TableRow>
          </TableHeadName>
          <>
            {expenseHistory === undefined && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  position: 'absolute',
                  alignItems: 'center',
                  height: '35%',
                  width: '-webkit-fill-available',
                }}
              >
                <NoDatTextSize>
                  No payment added yet
                </NoDatTextSize>
              </Box>
            )}
            {expenseHistory && expenseHistory[0]?.technicianHistory?.length === 0 &&
              (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    position: 'absolute',
                    alignItems: 'center',
                    height: '35%',
                    width: '-webkit-fill-available',
                  }}
                >
                  <NoDatTextSize>
                    No payment added yet
                  </NoDatTextSize>
                </Box>
              )
            }
            {!orderDetail?.orderDetailData ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  position: 'absolute',
                  alignItems: 'center',
                  height: '60%',
                  width: '-webkit-fill-available',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            ) : (
              <TableBody>
                {orderDetail?.orderDetailData &&
                  expenseHistory && expenseHistory[0]?.technicianHistory?.length !== 0 &&
                  expenseHistory?.map((item: any, index: number) => (
                    <React.Fragment key={index}>
                      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell align="center">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={(e) => openAccordian(item, index)}
                          >
                            {openRows[index] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">
                          {item.technicianName}
                        </TableCell>
                        <TableCell align="center">{item.description}</TableCell>
                        <TableCell align="center">{formatIndianCurrency(item.amount)}</TableCell>
                        <TableCell align="center">
                          {formatIndianCurrency(item.advancePayment)}
                        </TableCell>
                        <TableCell align="center">
                          {formatIndianCurrency(item.remainingPayment)}
                        </TableCell>
                        <TableCell align="center">
                          {item.deliveryDate === null ? '-' :
                            dayjs(item?.deliveryDate).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell align="center">
                          <FormControl sx={{ minWidth: 100 }} size="small">
                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              sx={{ align: "center", minWidth: '130px' }}
                              value={dropdownValues[item._id] || item.status}
                              label="status"
                              onChange={(e) => {
                                handleDropdownConfirmationAction(e, item);
                              }}
                            >
                              {options.map((data: any, index: any) => (
                                <MenuItem value={data.value} key={index}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', alignItems: 'center' }} align="center" width={100}>
                          <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseModel}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                '&:before': {
                                  content: '""',
                                  display: 'block',
                                  position: 'absolute',
                                  top: 0,
                                  right: 14,
                                  width: 5,
                                  height: 5,
                                  bgcolor: 'background.paper',
                                  transform: 'translateY(-50%) rotate(45deg)',
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: 'right',
                              vertical: 'top',
                            }}
                            anchorOrigin={{
                              horizontal: 'right',
                              vertical: 'bottom',
                            }}
                          >
                          </Menu>
                          <AddCircleOutlineIcon
                            onClick={(e) => handleViewModel(e, item)}
                            style={{
                              paddingLeft: '6px',
                              alignItems: 'center',
                            }}
                          >
                            <MoreVertIcon />
                          </AddCircleOutlineIcon>
                          <EditButton
                            navigateToEdit={() => navToEditExpense(item)}
                          />
                          <DeleteButton
                            handleOpenDeleteModal={() =>
                              handleRemoveExpense(item._id)
                            }
                          />
                        </TableCell>
                      </TableRow>
                      {openRows[index] && (
                        <TableRow>
                          <TableCell
                            style={{
                              paddingBottom: 0,
                              paddingTop: 0,
                              backgroundColor: 'rgb(249, 250, 252)',
                            }}
                            colSpan={12}
                          >
                            <CollapseContainer
                              in={openRows[index]}
                              timeout="auto"
                              unmountOnExit

                            >
                              <Box
                                sx={{
                                  justifyContent: 'space-between',
                                  display: 'flex',
                                }}
                              >
                                <CustomText
                                  gutterBottom

                                >
                                  Payment History{' '}
                                </CustomText>
                                {item.technicianHistory[
                                  item.technicianHistory.length - 1
                                ]?.remainingPayment === 0 && (
                                    <Button
                                      size="medium"
                                      color="success"
                                      variant="contained"
                                      style={{ marginTop: 10, paddingRight: 16, height: 36 }}
                                      endIcon={
                                        <CheckCircleIcon
                                          style={{ color: 'white' }}
                                        />
                                      }
                                    >
                                      Settled
                                    </Button>
                                  )}
                              </Box>
                              <TableBox>
                                <TableDesign
                                  size="small"
                                  aria-label="purchases"

                                >
                                  <TableHead>
                                    <StyledRow>
                                      {HsitoryTableHeader?.map(
                                        (item: string, index: number) => (
                                          <TableHeader

                                            key={index}
                                            align="center"
                                          >
                                            {item}
                                          </TableHeader>
                                        )
                                      )}
                                    </StyledRow>
                                  </TableHead>
                                  {item.technicianHistory?.map(
                                    (history: any, index: number) => (
                                      <TableBody>
                                        <TableRow key={history._id}>
                                          <TableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                          >
                                            {dayjs(history?.paymentDate).format(
                                              'DD/MM/YYYY'
                                            )}
                                          </TableCell>
                                          <TableCell align="center">
                                            {formatIndianCurrency(history.amount)}
                                          </TableCell>
                                          <TableCell align="center">
                                            {formatIndianCurrency(history.remainingPayment)}
                                          </TableCell>
                                          {index === 0 ? (
                                            <TableCell align="center">
                                              -
                                            </TableCell>
                                          ) : (
                                            <TableCell align="center">
                                              <EditButton
                                                navigateToEdit={() =>
                                                  navToEditTechnicianPayment(
                                                    item.technicianHistory, index
                                                  )
                                                }
                                              />
                                              <DeleteButton
                                                handleOpenDeleteModal={() =>
                                                  handleRemoveTechnicianPayment(
                                                    history._id
                                                  )
                                                }
                                              />
                                            </TableCell>
                                          )}
                                        </TableRow>
                                      </TableBody>
                                    )
                                  )}
                                </TableDesign>
                              </TableBox>
                            </CollapseContainer>
                            <TableRow></TableRow>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
              </TableBody>
            )}
          </>
        </Table>
      </TableContainer>
      {expenseHistory?.length !== 0 &&
        <Grid
          container
          marginTop={2}
          alignItems={'center'}
          justifyContent={'end'}
          sx={{ paddingRight: 7 }}
        >
          <Grid item alignItems={'center'}>
            <Typography
              sx={{
                fontWeight: 'bold',
                alignItems: 'center',
              }}
            >
              Total Expenses :
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              justifyContent: 'end',
              alignItems: 'center',
              ml: 2,
            }}
          >
            {formatIndianCurrency(orderDetail?.orderDetailData &&
              orderDetail?.orderDetailData?.totalExpenses)}
          </Grid>
        </Grid>}
      {showEditModal && (
        <EditTechnicianPaymentModal
          handleClose={closeEditTechnicianPaymentModal}
          open={showEditModal}
          handleUpdate={updateData}
          updateData={editTechnicianPaymentData}
          expensePaymentHistory={expensePaymentHistory}
        />
      )}
      {openTechnicianPaymentModal && (
        <TechnicianPaymentModal
          handleClose={closeTechnicianPaymentModal}
          open={openTechnicianPaymentModal}
          handleSave={saveData}
          technicianPaymentData={technicianPaymentData}
        />
      )}
      <DropdownModal
        loading={deleteTechnicianPaymentLoader}
        title=" Are you sure you want to Delete ?"
        isOpen={showDeleteModal}
        handleClose={handleDeleteModalClose}
        handleClickYes={handleDeleteModalYes}
      />
      <DropdownModal
        loading={deleteExpensePaymentLoader}
        title=" Are you sure you want to Delete ?"
        isOpen={showExpenseDeleteModal}
        handleClose={handleDeleteExpenseModalClose}
        handleClickYes={handleDeleteExpenseModalYes}
      />
      <DropdownModal
        loading={editExpenseStatusLoader}
        title=" Are you sure you want to change the status?"
        isOpen={showDropdownConfirmation}
        handleClose={handleCloseDropdownConfirmationModal}
        handleClickYes={handleClickYes}
        dropdownModalValues={dropdownModalValues}
      />
    </CardContainer>
  );
};

export default ExpenseHistory;
