import { Box, Button, Card, Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DeleteButton from '../../../../components/DeleteButton';
import { useSelector } from 'react-redux';
import AddEditMeasurement from '../../../Measurement/AddEditMeasurement';
import DropdownModal from '../../../../components/ConfirmationModal';
import { useDispatch } from 'react-redux';
import { deleteMeasurement } from '../../../../store/mesurement/action';
import { useLocation } from 'react-router-dom';
import NoDataImage from '../../../../components/NoDataImage';
import MeasurementSearchModal from '../../../../components/MeasureMentSearch';
import EditButton from '../../../../components/EditButton';
import PrintIcon from '@mui/icons-material/Print';
import { editOrder } from '../../../../store/order/action';

const BASE_URL = process.env.REACT_APP_API_URL;

const Item = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  boxShadow: 'none',
}));


const MainContainer = styled(Box)({
  width: '100%',
});

const CardWrapper = styled(Card)({
  boxShadow: '0px 2px 10px 0px rgb(58 53 65 / 10%) !important',
  borderRadius: '6px !important',
  padding: '20px',
  height: '65vh',
  overflowY: 'auto !important' as 'auto',
});


const MeasurementLabel = styled(Typography)({
  width: '50%',
  textAlign: 'left',
  fontWeight: 'bold !important',
  fontSize: '18 !important',
});


const MeasurementTab = () => {
  const [deleteItemId, setDeleteItemId] = useState<string>('');
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [handledelete, setHandledelete] = useState(false);
  const deleteMeasurementloading = useSelector(
    (state: any) => state.deleteMeasurement?.loading
  );
  const auth = useSelector((state: any) => state.auth);
  const location = useLocation();
  const [openSearch, setOpenSearch] = React.useState(false);
  const isLoader = useSelector((state: any) => state?.mesurementList.isLoading);
  const [isAddModal, setIsModal] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const mesurementList = useSelector(
    (state: any) => state.mesurementList?.measurementData?.data
  );
  const handleClose = () => {
    setShowDeleteModal(false);
    setDeleteItemId('');
  };
  const handleCloseSearch = () => {
    setOpenSearch(false);
  };
  useEffect(() => {
    if (!isLoader) {
      setIsModal(false);
    }
  }, [!isLoader]);
  useEffect(() => {
    if (
       !deleteMeasurementloading &&
     handledelete &&
      location.state !== null &&
      mesurementList &&
      mesurementList.length > 0
    ) {
      const formData = new FormData();
      dispatch(editOrder(formData, auth.data._id, location.state));
    }
  }, [!deleteMeasurementloading, ,handledelete,location?.state]);

  useEffect(() => {
    if (!deleteMeasurementloading) {
      setShowDeleteModal(false);
    }
  }, [deleteMeasurementloading]);

  const measurementData = useSelector(
    (state: any) => state?.orderDetail.orderDetailData.measurement
  );
  let measureDataShow: any = [];
  {
    measurementData?.map((item: any) => {
      measureDataShow = item;
    });
  }
  const navigateToEdit = (data: any) => {
    setEditedData(data);
    setIsModal(true);
  };
  const handleDeleteModalYes = () => {
    dispatch(
      deleteMeasurement({
        measurementId: deleteItemId,
        payload: {
          measurementId: deleteItemId,
        },
      })
    );
  };
  const handleOpenDeleteModal = (deleteId: string) => {
    setHandledelete(true)
    setDeleteItemId(deleteId);
    setShowDeleteModal(true);
  };

  const printMeasurement = async (id: any) => {
    try {
      const getToken = () => {
        return window.localStorage.getItem('userToken') || '';
      };
      const token = getToken();
      const data = {
        measurementId: id,
      };
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.post(
        `${BASE_URL}/measurement/print`,
        data,
        config
      );
      if (response) {
        const printWindow = window.open('', '_blank');
        printWindow?.document.write(response.data);
        printWindow?.document.close();
        printWindow?.print();
      }
    } catch (error) {
    }
  };
  return (
    <div>
      <MainContainer>
        <CardWrapper>
          <Box
            sx={{
              display: 'flex',
              marginBottom: '10px',
              justifyContent: 'right',
            }}
          >
            <Button
              size='medium'
              sx={{
                backgroundColor: '#9155FD !important',
                marginRight: '24px',
              }}
              variant='contained'
              onClick={() => setOpenSearch(true)}
            >
             {measurementData.length === 0 ? 'Add' : 'Update'}
            </Button>
          </Box>
          {measurementData.length === 0 ? (
            <NoDataImage />
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  gap: '4%',
                  width: '100%',
                  marginTop: 4,
                  marginLeft: 2,
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: 17,
                    fontWeight: 'bold',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  Customer Name :
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: 17,
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  {measureDataShow.customerName}
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: 17,
                    fontWeight: 'bold',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  Customer Number :
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: 17,
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  {measureDataShow.customerNumber}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: '95%',
                  boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3)',
                  padding: 2,
                  marginTop: 3,
                  marginLeft: 2,
                  borderRadius: 2,
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 7,
                    right: 13,
                    zIndex: 1,
                    fontSize: '29px',
                  }}
                >
                  <EditButton
                    navigateToEdit={() => navigateToEdit(measureDataShow)}
                  />
                  <DeleteButton
                    handleOpenDeleteModal={() =>
                      handleOpenDeleteModal(measureDataShow._id)
                    }
                  />
                  <PrintIcon sx={{ pl: '6px' }} onClick={() => printMeasurement(measureDataShow._id)}/>
                </Box>
                <Box sx={{ width: '100%', mt: 5 }}>
                  <Grid
                    container
                    spacing={1}
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Length :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.length}{' '}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>U.Chest :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.uChest}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Chest :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.chest}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Waist :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.waist}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>L-Waist :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.lWaist}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Sheet :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.sheet}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Hips :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.hips}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Knee :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.knee}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Ankal :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.ankle}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Shoulder :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.shoulder}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Sleeve Length :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.sleeveLength}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Armhole :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.armhole}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Biceps :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.biceps}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Around Elbow :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.aroundElbow}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Around Wrist :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.aroundWrist}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Front Neck :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.frontKneck}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Back Neck :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.backKneck}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={4}>
                      <Item>
                        <MeasurementLabel>Bottom Length :</MeasurementLabel>
                        <Typography>
                          {measureDataShow.measurement?.bottomLength}
                        </Typography>
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </>
          )}

          {isAddModal && (
            <AddEditMeasurement
              open={isAddModal}
              editedData={editedData}
              onClose={() => {
                setIsModal(false);
                setEditedData(null);
              }}
            />
          )}
          <DropdownModal
            loading={deleteMeasurementloading}
            title=' Are you sure you want to Delete ?'
            isOpen={showDeleteModal}
            handleClose={handleClose}
            handleClickYes={handleDeleteModalYes}
          />
          <MeasurementSearchModal
            openSearch={openSearch}
            setOpenSearch={setOpenSearch}
            handleCloseSearch={handleCloseSearch}
          />
        </CardWrapper>
      </MainContainer>
    </div>
  );
};

export default MeasurementTab;
